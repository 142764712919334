import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import LoadingAnimation from "../animation/LoadingAnimation";
import { ApplicationState } from "../reducers";
import { OrganisationInfo } from "../services/adminService";
import {
  getOperatingModes,
  setOperatingMode,
} from "../services/organisationService";
import { Status, StatusMessage } from "../status/status";
import { ONE_HOUR_AGO } from "./firestore/triangulations";
import { ScoutState } from "./model/scout";
import { addPopupAction } from "./organisationReducer";

const StatusSummary = ({ name, count }: { name: string; count: number }) => {
  return (
    <div className={"Organisation-status-summary"}>
      <p>{name}</p>
      <p>{count}</p>
    </div>
  );
};

const OperatingMode = ({
  organisationInfo,
  organisationJwt,
  addPopup,
}: {
  organisationInfo: OrganisationInfo;
  organisationJwt: string;
  addPopup: (_: StatusMessage) => void;
}) => {
  const [loading, setLoading] = React.useState(false);
  const [modes, setModes] = React.useState<string[]>([]);
  const [selectedMode, setSelectedMode] = React.useState<string | undefined>(
    organisationInfo.mode,
  );
  // Get all modes
  React.useEffect(() => {
    getOperatingModes(organisationJwt).then((m) => {
      setModes(m);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <LoadingAnimation height={30} width={30} />;
  }

  return (
    <div>
      <h3 style={{ marginBottom: "0" }}>Operation mode</h3>
      <select
        name="orderBy"
        id="orderBy"
        className={"Widget-dropdown"}
        style={{ marginLeft: "0rem" }}
        value={selectedMode}
        onChange={(e) => {
          const newMode = e.target.value;
          console.log("MODE", "Before!");
          setOperatingMode(newMode, organisationJwt)
            .then((success) => {
              console.log("MODE", "success!", success);
              if (success) {
                console.log("MODE", "Print success!");
                setSelectedMode(newMode);
              }
              console.log("MODE", "after!");
              addPopup(createChangedModeMessage(newMode, success));
              setLoading(false);
            })
            .catch((e) => {
              addPopup(createChangedModeMessage(newMode, false));
              setLoading(false);
            });
        }}
      >
        {modes.map((m) => (
          <option value={m}>{m}</option>
        ))}
      </select>
      <div>
        {
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            href={`https://console.firebase.google.com/project/triangula-385d2/firestore/databases/-default-/data/~2Fenv~2Fprod~2Fapp~2Fconfiguration~2Fmodes~2F${selectedMode}`}
            className={"Dashboard-clickable-text Dashboard-no-decoration"}
            style={{ paddingLeft: "0" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            GO TO {selectedMode} MODE
          </a>
        }
      </div>
    </div>
  );
};

const OrganisationStatus = ({
  observationCount,
  listeningScoutCount,
  gunshotCount,
  organisationInfo,
  organisationJwt,
  addPopup,
}: Props) => {
  return (
    <>
      <section
        className={["Organisation-status-section", "Organisation-section"].join(
          " ",
        )}
      >
        <StatusSummary name={"Shots last hour"} count={gunshotCount} />
        <StatusSummary name={"Listening devices"} count={listeningScoutCount} />
        <StatusSummary name={"Events last minute"} count={observationCount} />
      </section>
      <section
        className={["Organisation-status-section", "Organisation-section"].join(
          " ",
        )}
      ></section>
      {organisationInfo && organisationJwt && (
        <section className="Widget-item">
          <OperatingMode
            organisationInfo={organisationInfo}
            organisationJwt={organisationJwt}
            addPopup={addPopup}
          />
        </section>
      )}
    </>
  );
};

const createChangedModeMessage = (
  mode: string,
  success: boolean,
): StatusMessage => {
  return {
    id: "changed-mode",
    message: success
      ? `Changed to ${mode} mode`
      : `Failed to change to ${mode} mode`,
    component: "Operating mode",
    status: success ? Status.Ok : Status.Critical,
    useInPopup: true,
  };
};

interface StateToProps {
  observationCount: number;
  listeningScoutCount: number;
  gunshotCount: number;
  organisationInfo?: OrganisationInfo;
  organisationJwt: string | undefined;
}

interface DispatchFromProps {
  addPopup: (_: StatusMessage) => void;
}

interface Props extends StateToProps, DispatchFromProps {}

const mapStateToProps = (state: ApplicationState) => ({
  observationCount: Object.values(state.organisation.observations).reduce(
    (acc, v) => acc + v.length,
    0,
  ),
  listeningScoutCount: Object.values(state.organisation.scouts).filter(
    (s) => s.state === ScoutState.Listening,
  ).length,
  gunshotCount: state.organisation.gunshots.filter(
    (g) => g.timestamp.getTime() > Date.now() - ONE_HOUR_AGO,
  ).length,
  organisationInfo: state.organisation.organisationInfo,
  organisationJwt: state.organisation.userJwtToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addPopup: addPopupAction,
    },
    dispatch,
  );

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps,
)(OrganisationStatus);
