import React, { useEffect, useState } from "react";
import { GunshotObservation, RoundGunshots } from "../model/rounds";
import { ApplicationState } from "../reducers";
import { bindActionCreators, Dispatch } from "redux";
import {
  inputFieldInFocusAction,
  manualUpdateSampleAction,
  selectDeviceAction,
  selectRoundAction,
  addTimingAction,
  selectRoundNameAction,
  estimatedToManualDiffAction,
  toggleNoiseReductionAction,
  selectedSampleAction,
  showTimingPredictionsAction,
  addDirectionPredictionAction,
  showPeakCategoryAction,
} from "./roundReducer";
import { connect } from "react-redux";
import Player from "../player/Player";
import { Incident } from "../model/incident";
import { createImageUrl, createUrlForShot } from "../audio/audioUtil";
import {
  AudioFile,
  ClassificationScore,
  getClassifications,
  getPeaks,
  getTimings,
  DistancePredictions,
  getOutlierData,
  getTimingPredictionsGraphData,
  getDirectionPrediction,
  TimingCategories,
} from "../services/adminService";
import RoundImageOverlay from "./RoundImageOverlay";
import { createAudioObjectPath, tutToDate } from "../util";
import {
  ManualUpdateSample,
  updateManualAligned,
  markAsUnclearPeak,
  getRoundInfo,
  updateManualSonicBoom,
} from "../services/roundsService";
import { store } from "../index";
import { formatDate } from "../player/IncidentItemInfo";
import LoadingAnimation from "../animation/LoadingAnimation";
import ExtendedSliceOverlay from "./ExtendedSliceOverlay";
import {
  S_KEY,
  M_KEY,
  QUESTION_MARK_KEY,
  U_KEY,
  Y_KEY,
} from "../shortcut/keys";
import NextShotOnSave from "./NextShotOnSave";
import { updateUrl } from "./urlUtil";
import DecibelAdjuster from "./DecibelAdjuster";
import SonicBoomMode from "./SonicBoomMode";
import PeakCategoryOverlay from "./PeakCategoryOverlay";
import NoiseReduction from "./NoiseReduction";
import OutlierOverlay from "../outlier/OutlierOverlay";
import PredictionChart from "../plot/PredictionChart";
import RawJsonOverlay from "../json/RawJsonOverlay";
import AddToGunshotDirection from "./AddToGunshotDirection";
import ModelAnalyzerOverlay from "./ModelAnalyzerOverlay";
import ShiftAudioOverlay from "./ShiftAudioOverlay";

const Predictions = ({
  jwtToken,
  audioFile,
}: {
  jwtToken: string;
  audioFile: AudioFile;
}) => {
  const [timePredictions, setTimePredictions] = useState<
    { [id: string]: number[] } | undefined
  >(undefined);
  useEffect(() => {
    setTimePredictions(undefined);
    getTimingPredictionsGraphData(audioFile, jwtToken)
      .then((v) => setTimePredictions(v))
      .catch(() => console.log("Couldn't fetch predictions"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioFile.filename]);

  return (
    <>
      {timePredictions !== undefined && (
        <div
          style={{
            position: "absolute",
            marginTop: "-335px",
            marginLeft: "-60px",
            width: "100%",
            height: "250px",
            background: "rgba(255,255,255,0.4)",
            zIndex: 10,
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <PredictionChart
            graphData={timePredictions["predictions"]}
            height={200}
            width={window.innerWidth * 0.666 + 62}
          />
        </div>
      )}
    </>
  );
};

const Audio = ({
  jwtToken,
  selectedRound,
  decibel,
  sonicBoomMode,
  noiseReduction,
  liteState,
  gunshotDirectionScouts,
}: {
  jwtToken: string;
  selectedRound: GunshotObservation;
  decibel: string;
  sonicBoomMode: boolean;
  noiseReduction: boolean;
  liteState: boolean;
  openTimingPrediction: boolean;
  gunshotDirectionScouts: string[];
}) => {
  const [classification, setClassification] = useState<ClassificationScore[]>(
    [],
  );
  const [timingClassificationScore, setTimingClassificationScore] = useState<
    ClassificationScore[]
  >([]);
  const [peak, setPeak] = useState<{ [_: string]: number[] }>({});
  const [timingCategories, setTimingCategories] = useState<
    TimingCategories | undefined
  >(undefined);
  const [distances, setDistances] = useState<DistancePredictions | undefined>(
    undefined,
  );
  const [objectPath, setObjectPath] = useState("");
  const [manualSample, setManualSample] = useState(0);

  useEffect(() => {
    const aFile: AudioFile = {
      "bucket-name": selectedRound.audio.file_location.bucket,
      filename: createAudioObjectPath(selectedRound),
    };
    if (aFile.filename !== objectPath) {
      setClassification([]);
      setTimingClassificationScore([]);
      setPeak({});
      setTimingCategories(undefined);
      setDistances(undefined);
      setObjectPath(aFile.filename);
      getAndSetTimingIfAmmoAndTargetIsSet(selectedRound, jwtToken);

      if (!liteState) {
        getClassifications(aFile, jwtToken, (c) => setClassification(c));
        getPeaks(aFile, selectedRound, jwtToken, (p, c) => {
          setPeak(p);
          setTimingClassificationScore(c);
        });
      }
    }

    if (
      selectedRound.selectedSample &&
      selectedRound.selectedSample.sample_number !== manualSample
    ) {
      const newSampleNumber = selectedRound.selectedSample.sample_number;
      setManualSample(newSampleNumber);
    }
    // eslint-disable-next-line
  }, [selectedRound, jwtToken, objectPath]);

  useEffect(() => {
    getAndSetTimingIfAmmoAndTargetIsSet(selectedRound, jwtToken);
    // eslint-disable-next-line
  }, [selectedRound.shot.ammunition?.v0_calculated]);

  useEffect(() => {
    getDirectionPredictions(
      gunshotDirectionScouts,
      store.getState().round.selectedRound!.observations,
      jwtToken,
    );
  }, [
    objectPath,
    gunshotDirectionScouts.length,
    gunshotDirectionScouts,
    jwtToken,
  ]);
  const roundAsIncident: Incident = createIncident(
    selectedRound,
    [...classification, ...timingClassificationScore],
    peak,
    {},
    timingCategories,
    distances,
  );

  const manual = findPersistedManualSampleValue(selectedRound);
  const manualSonicBoom = findManualSonicBoomValue(selectedRound);

  const additionalPeaks: { [_: string]: number[] } = {
    Estimated: [selectedRound.audio.estimated_detection.sample_number],
    Manual: manual,
    ManualSonicBoom: manualSonicBoom,
  };

  const relativePeaks = createRelativePeaks(selectedRound);

  return (
    <div style={{ width: "100%", position: "relative" }}>
      {sonicBoomMode && (
        <h1
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
          }}
        >
          SONIC BOOM MODE
        </h1>
      )}
      <div style={{ position: "relative" }}>
        <Player
          width={"100%"}
          incident={roundAsIncident}
          jwtToken={jwtToken}
          url={createUrlForShot(
            selectedRound,
            +decibel,
            selectedRound.audio.noise_reduction !== undefined &&
              selectedRound.audio.noise_reduction !== null &&
              noiseReduction,
          )}
          additionalPeaks={additionalPeaks}
          relativePeaks={relativePeaks}
          onCursorChange={(timesample: number) => {
            store.dispatch(
              selectedSampleAction({
                author: "Not saved",
                sampleNumber: parseInt(timesample.toFixed(0)),
                tut: 0,
                unpersisted: true,
              }),
            );
          }}
          persistedSample={selectedRound.manual?.sample_number ?? undefined}
          copilot={selectedRound.manual?.copilot ?? false}
          samplePrecision={selectedRound.manual?.sample_precision ?? undefined}
          tut={selectedRound.audio.tut}
        />
        {store.getState().round.showTimingPredictions && (
          <Predictions
            jwtToken={jwtToken}
            audioFile={{
              "bucket-name": selectedRound.audio.file_location.bucket,
              filename: createAudioObjectPath(selectedRound),
            }}
          />
        )}
      </div>
    </div>
  );
};

const ExtendedSlice = ({
  jwtToken,
  observation,
  audioFile,
  decibel,
  noiseReduction,
  showPeakCategories,
}: {
  observation: GunshotObservation;
  jwtToken: string;
  audioFile: AudioFile | undefined;
  openTimingPrediction: boolean;
  decibel: string;
  noiseReduction: boolean;
  showPeakCategories: (_ : boolean) => void;
}) => {
  const [id, setId] = useState(observation.image.file_location.filename);
  const [openImage, setOpenImage] = useState(false);
  const [loading, setLoading] = useState(false);
  //@ts-ignore
  useEffect(() => {
    if (id !== observation.image.file_location.filename) {
      setLoading(false);
      setId(observation.image.file_location.filename);
    }
  }, [id, observation]);

  return (
    <>
      {openImage && (
        <ExtendedSliceOverlay
          incident={createIncident(observation, [], {}, {}, undefined)}
          jwtToken={jwtToken!}
          url={createUrlForShot(
            observation,
            +decibel,
            observation.audio.noise_reduction !== undefined &&
              observation.audio.noise_reduction !== null &&
              noiseReduction,
            5,
            5,
          )}
          imgUrl={createImageUrl(observation, 5, 5)}
          observation={observation}
          closeCallback={() => setOpenImage(false)}
        />
      )}

      <p
        style={{
          marginRight: "1rem",
          marginLeft: "1rem"
        }}
        className={"Dashboard-action-button"}
        onClick={() => {
          store.dispatch(
            showTimingPredictionsAction(
              !store.getState().round.showTimingPredictions,
            ),
          );
        }}
      >
        Timing
      </p>

      <p
        style={{
          marginRight: "1rem",
          marginLeft: "1rem"
        }}
        className={"Dashboard-action-button"}
        onClick={() => {
          showPeakCategories(true);
        }}
      >
        Peak categories
      </p>
      <p
        style={{
          marginRight: "1rem",
          marginLeft: "1rem"
        }}
        className={"Dashboard-action-button"}
        onClick={() => {
          !loading && setOpenImage(true);
        }}
      >
        {loading ? "Loading" : "Extended slice"}
      </p>
    </>
  );
};

const ManualTiming = ({
  jwtToken,
  observation,
}: {
  observation: GunshotObservation;
  jwtToken: string;
}) => {
  const [value, setValue] = useState(initManualSampleNumber(observation));
  const [initValue, setInitValue] = useState(
    initManualSampleNumber(observation),
  );
  const [observationId, setObservationId] = useState(
    `${observation.device.name}_${observation.shot.index}`,
  );
  const [unadjustableLoading, setUnadjustableLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  // eslint-disable-next-line
  useEffect(() => {
    const newId = `${observation.device.name}_${observation.shot.index}`;
    if (newId !== observationId) {
      setInitValue(initManualSampleNumber(observation));
      setValue(
        observation.selectedSample
          ? `${observation.selectedSample.sample_number}`
          : "",
      );
      initManualSampleNumber(observation);
      setObservationId(newId);
      setError(undefined);
      setLoading(false);
    }

    if (initManualSampleNumber(observation) !== initValue) {
      setInitValue(initManualSampleNumber(observation));
      setValue(initManualSampleNumber(observation));
    }
  });

  useEffect(() => {
    const saveKeyHandler = (e: KeyboardEvent) => {
      if (store.getState().round.inputFieldInFocus) {
        return;
      }
      if (
        S_KEY === e.keyCode &&
        observation.selectedSample &&
        observation.selectedSample.sample_number
      ) {
        manualTimerSubmit(observation.selectedSample.sample_number);
      } else if (
        Y_KEY === e.keyCode &&
        observation.selectedSample &&
        observation.selectedSample.sample_number
      ) {
        manualTimerSubmit(observation.selectedSample.sample_number, true);
      } else if (M_KEY === e.keyCode && !store.getState().round.sonicBoomMode) {
        unclearPeakSubmit();
      } else if (QUESTION_MARK_KEY === e.keyCode) {
        alert(
          "CHEAT_SHEET\nS - save sample at current location\nM - Mark as ambiguous\nN - Normalize\nL - Lock zoom\nA - one sample to the left\nU - Reduce noise\nSHIFT + A - 10 sample to the left\nD - one sample to the right\nSHIFT + D - 10 sample to the right\nR - Repeat previous estimated to set timing diff\n1 - No zoom\n2 - Half of max zoom\n3 - Max zoom\ng - Set cursor at Gale\ne - Set cursor at Ed\nSPACE - Play audio\n. Set cursor at start",
        );
      }

      if (U_KEY === e.keyCode) {
        store.dispatch(toggleNoiseReductionAction());
      }
    };

    document.addEventListener("keydown", saveKeyHandler);
    return () => {
      document.removeEventListener("keydown", saveKeyHandler);
    };
  });

  const manualTimerSubmit = (
    sampleNumber: number,
    lowAccuracy: boolean = false,
  ) => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (store.getState().round.sonicBoomMode) {
      updateManualSonicBoom(
        jwtToken,
        observation.round.name,
        observation.shot.index,
        observation.device.name,
        sampleNumber,
        observation.audio.estimated_detection.sample_number,
        lowAccuracy,
        (manualUpdated: ManualUpdateSample) => {
          store.dispatch(manualUpdateSampleAction(manualUpdated));
          store.dispatch(
            estimatedToManualDiffAction(
              sampleNumber -
                observation.audio.estimated_detection.sample_number,
            ),
          );
          setLoading(false);
          if (store.getState().round.nextShotOnSave) {
            getRoundInfo(
              jwtToken,
              observation.round.name,
              observation.shot.index + 1,
              (newRound) => {
                store.dispatch(selectRoundAction(newRound));
                store.dispatch(selectRoundNameAction(observation.round.name));
                updateUrl(store.getState().round.selectedDevice);
              },
            );
          }
        },
        (msg: string) => {
          setLoading(false);
          setError(msg);
        },
      );
    } else {
      updateManualAligned(
        jwtToken,
        observation.round.name,
        observation.shot.index,
        observation.device.name,
        sampleNumber,
        observation.audio.estimated_detection.sample_number,
        lowAccuracy,
        (manualUpdated: ManualUpdateSample) => {
          store.dispatch(manualUpdateSampleAction(manualUpdated));
          store.dispatch(selectedSampleAction(manualUpdated));
          store.dispatch(
            estimatedToManualDiffAction(
              sampleNumber -
                observation.audio.estimated_detection.sample_number,
            ),
          );
          setLoading(false);
          if (store.getState().round.nextShotOnSave) {
            if (observation.round.total_shots > observation.shot.index) {
              getRoundInfo(
                jwtToken,
                observation.round.name,
                observation.shot.index + 1,
                (newRound) => {
                  store.dispatch(selectRoundAction(newRound));
                  store.dispatch(selectRoundNameAction(observation.round.name));
                  updateUrl(store.getState().round.selectedDevice);
                },
              );
            }
          }
        },
        (msg: string) => {
          setLoading(false);
          setError(msg);
        },
      );
    }
  };

  const unclearPeakSubmit = () => {
    if (unadjustableLoading) {
      return;
    }
    setUnadjustableLoading(true);
    markAsUnclearPeak(
      jwtToken,
      observation.round.name,
      observation.shot.index,
      observation.device.name,
      () => {
        store.dispatch(
          manualUpdateSampleAction({
            author: "",
            sampleNumber: 0,
            tut: 0,
            unadjustable: true,
          }),
        );
        setUnadjustableLoading(false);
        if (store.getState().round.nextShotOnSave) {
          getRoundInfo(
            jwtToken,
            observation.round.name,
            observation.shot.index + 1,
            (newRound) => {
              store.dispatch(selectRoundAction(newRound));
              store.dispatch(selectRoundNameAction(observation.round.name));
              updateUrl(store.getState().round.selectedDevice);
            },
          );
        }
      },
      (msg: string) => {
        setUnadjustableLoading(false);
        setError(msg);
      },
    );
  };

  return (
    <div style={{ marginLeft: "1.25rem" }}>
      <div
        style={{ display: "flex", alignItems: "flex-end"}}
      >
        <div>
          <div className={"Round-label"}>Manual timing</div>
          <input
            className={"Round-input"}
            value={value}
            disabled={loading}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            onFocus={() => {
              store.dispatch(inputFieldInFocusAction(true));
            }}
            onBlur={() => {
              store.dispatch(inputFieldInFocusAction(false));
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                manualTimerSubmit(+value);
                store.dispatch(inputFieldInFocusAction(false));
              }
            }}
          />
        </div>
        <div
          className={"Round-button"}
          onClick={() => manualTimerSubmit(+value)}
        >
          {loading ? (
            <LoadingAnimation height={15} width={15} color={"white"} />
          ) : (
            <span>
              <u>S</u>AVE
            </span>
          )}
        </div>

        {!store.getState().round.sonicBoomMode && (
          <div
            style={
              observation.selectedSample &&
              observation.selectedSample.low_accuracy
                ? { backgroundColor: "#029959", color: "white" }
                : {}
            }
            className={"Round-adjustable-button Round-button"}
            onClick={() => manualTimerSubmit(+value, true)}
          >
            {loading ? (
              <LoadingAnimation height={15} width={15} color={"white"} />
            ) : (
              <span>
                {((observation.manual && observation.manual.low_accuracy) ||
                  (observation.selectedSample &&
                    observation.selectedSample.low_accuracy)) && (
                  <span>MARKED</span>
                )}{" "}
                LOW ACCURAC<u>Y</u>
              </span>
            )}
          </div>
        )}
        {store.getState().round.sonicBoomMode ? (
          <></>
        ) : (observation.manual && observation.manual.unadjustable) ||
          (observation.selectedSample &&
            observation.selectedSample.unadjustable) ? (
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              color: "#CC0B06",
              marginBottom: "5px",
              marginLeft: "10px",
            }}
          >
            Marked as ambiguous
          </span>
        ) : (
          <div
            className={"Round-adjustable-button Round-button"}
            onClick={() => unclearPeakSubmit()}
          >
            {unadjustableLoading ? (
              <LoadingAnimation height={15} width={15} color={"#0B1525"} />
            ) : (
              <span>
                A<u>M</u>BIGUOUS
              </span>
            )}
          </div>
        )}
        <div style={{display: "flex"}}>
          <NextShotOnSave />
          <SonicBoomMode />
        </div>
        {observation.audio.noise_reduction !== undefined &&
          observation.audio.noise_reduction != null && <NoiseReduction />}
        <DecibelAdjuster />
      </div>
      {error && (
        <div style={{ color: "red" }} className={"Round-updated-by"}>
          {error}
        </div>
      )}
      {observation.selectedSample && (
        <div className={"Round-updated-by"}>
          Last local update{" "}
          {formatDate(tutToDate(observation.selectedSample.tut * 1000000))} by{" "}
          {`${observation.selectedSample.author} `}
          {observation.manual && (
            <span>
              | Last persisted{" "}
              {formatDate(tutToDate(observation.manual.tut * 1000000))} by{" "}
              {`${observation.manual.author} (${observation.manual.sample_number})`}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

interface ExtraControllerProps extends Props {
  liteState: boolean;
  setLiteState: (_: boolean) => void;
  openTimingPrediction: boolean;
  showPeakCategories: (_: boolean) => void;
}

const ExtraController = ({
  selectedDevice,
  selectedRound,
  jwtToken,
  liteState,
  setLiteState,
  openTimingPrediction,
  decibel,
  noiseReduction,
  showPeakCategories,
}: ExtraControllerProps) => {
  const [openImage, setOpenImage] = useState(false);
  const [openCam, setOpenCam] = useState(false);
  const [openShiftAudio, setOpenShiftAudio] = useState(false);
  const [openOutlierOverlay, setOpenOutlierOverlay] = useState(false);
  const [openRawJson, setOpenRawJson] = useState(false);

  const roundInfoForDevice = selectedRound!.observations.find(
    (s) => s.device.name === selectedDevice,
  )!;
  const audioFile =
    roundInfoForDevice !== undefined &&
    roundInfoForDevice.selectedSample !== undefined
      ? {
          "bucket-name": roundInfoForDevice.audio.file_location.bucket,
          filename: createAudioObjectPath(roundInfoForDevice),
        }
      : undefined;
      console.log("audioFile", openShiftAudio, audioFile)
  return (
    <div
      className={"Round-extra-controller"}
      style={{ zIndex: 500 }}
    >
      {openImage && (
        <RoundImageOverlay
          url={createImageUrl(roundInfoForDevice)}
          jwtToken={jwtToken!}
          closeCallback={() => setOpenImage(false)}
        />
      )}
      {/* {openCam && audioFile && (
        <ModelAnalyzerOverlay
          file={audioFile}
          jwtToken={jwtToken!}
          closeCallback={() => setOpenCam(false)}
        />
      )} */}
      {openShiftAudio && (
        <ShiftAudioOverlay
          gunshotObservation={roundInfoForDevice}
          jwtToken={jwtToken!}
          closeCallback={() => setOpenShiftAudio(false)}
        />
      )}
      {openOutlierOverlay && (
        <OutlierOverlay
          round={roundInfoForDevice}
          jwtToken={jwtToken!}
          closeCallback={() => setOpenOutlierOverlay(false)}
          apiRequest={() => {
            return getOutlierData(
              jwtToken!,
              roundInfoForDevice.round.name,
              roundInfoForDevice.device.name,
            );
          }}
        />
      )}
      {openRawJson && (
        <RawJsonOverlay
          round={roundInfoForDevice}
          jwtToken={jwtToken!}
          closeCallback={() => setOpenRawJson(false)}
        />
      )}
      <ManualTiming jwtToken={jwtToken!} observation={roundInfoForDevice} />
      <div style={{ display: "flex" }}>
        <ExtendedSlice
          jwtToken={jwtToken!}
          observation={roundInfoForDevice}
          audioFile={audioFile}
          openTimingPrediction={openTimingPrediction}
          decibel={decibel}
          noiseReduction={noiseReduction}
          showPeakCategories={showPeakCategories}
        />
        {/* <p
          style={{
            marginRight: "1rem",
            marginLeft: "1rem"
          }}
          className={"Dashboard-action-button"}
          onClick={() => setOpenCam(true)}
        >
          Cam
        </p> */}
        <p
          style={{
            marginRight: "1rem",
            marginLeft: "1rem"
          }}
          className={"Dashboard-action-button"}
          onClick={() => setOpenShiftAudio(true)}
        >
          Shift audio
        </p>
        <p
          style={{
            marginRight: "1rem",
            marginLeft: "1rem"
          }}
          className={"Dashboard-action-button"}
          onClick={() => setOpenImage(true)}
        >
          Debug
        </p>
        <p
          style={{
            marginRight: "1rem",
            marginLeft: "1rem"
          }}
          className={"Dashboard-action-button"}
          onClick={() => setOpenOutlierOverlay(true)}
        >
          Outliers
        </p>
        <p
          style={{
            marginRight: "1rem",
            marginLeft: "1rem"
          }}
          className={"Dashboard-action-button"}
          onClick={() => setOpenRawJson(true)}
        >
          JSON
        </p>
        <p
          style={{
            marginRight: "1rem",
            marginLeft: "1rem"
          }}
          className={"Dashboard-action-button"}
          onClick={() => setLiteState(!liteState)}
        >
          Lite {liteState ? "on" : "off"}
        </p>
      </div>
    </div>
  );
};

const SelectedGunshotSection = (props: Props) => {
  const [audioPlayerOnBottom, setAudioPlayerOnBottom] = useState(true);
  const [liteState, setLiteState] = useState(false);
  const {
    selectedDevice,
    selectedRound,
    jwtToken,
    selectDevice,
    decibel,
    sonicBoomMode,
    noiseReduction,
    openTimingPrediction,
    gunshotDirectionScouts,
  } = props;

  if (!selectedDevice) {
    return <></>;
  }
  const device = selectedRound!.observations.find(
    (s) => s.device.name === selectedDevice,
  )!;
  if (!device) {
    selectDevice(undefined);
    return <></>;
  }
  return (
    <div
      style={audioPlayerOnBottom ? { bottom: "0" } : { top: "-10px" }}
      className={"Round-selected-gunshot"}
    >
      <p
        style={{ marginBottom: 0, textAlign: "center" }}
        className={"Dashboard-action-button"}
        onClick={() => setAudioPlayerOnBottom(!audioPlayerOnBottom)}
      >
        Move audio window to {audioPlayerOnBottom ? "top" : "bottom"}
      </p>
      {device && (
        <AddToGunshotDirection
          device={device.device}
          sampleNumber={
            device.selectedSample
              ? device.selectedSample.sample_number
              : undefined
          }
        />
      )}
      <ExtraController
        {...props}
        liteState={liteState}
        setLiteState={setLiteState}
        showPeakCategories={props.showPeakCategories}
      />
      <div className={"Round-audio-section"}>
        <Audio
          jwtToken={jwtToken!}
          selectedRound={device}
          decibel={decibel}
          sonicBoomMode={sonicBoomMode}
          noiseReduction={noiseReduction}
          liteState={liteState}
          openTimingPrediction={openTimingPrediction}
          gunshotDirectionScouts={gunshotDirectionScouts}
        />
      </div>
    </div>
  );
};

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  selectedRound: RoundGunshots | undefined;
  decibel: string;
  jwtToken: string | undefined;
  selectedDevice: string | undefined;
  sonicBoomMode: boolean;
  noiseReduction: boolean;
  openTimingPrediction: boolean;
  gunshotDirectionScouts: string[];
}

interface DispatchToProps {
  selectRound: (_: RoundGunshots) => void;
  selectDevice: (_: string | undefined) => void;
  showPeakCategories: (_: boolean) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  selectedRound: state.round.selectedRound,
  filter: state.round.decibel,
  jwtToken: state.login.jwtToken,
  selectedDevice: state.round.selectedDevice,
  sonicBoomMode: state.round.sonicBoomMode ? true : false,
  decibel: state.round.decibel,
  noiseReduction: state.round.noiseReduction,
  openTimingPrediction: state.round.showTimingPredictions,
  gunshotDirectionScouts: state.round.gunshotDirectionScouts,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selectRound: selectRoundAction,
      selectDevice: selectDeviceAction,
      showPeakCategories: showPeakCategoryAction,
    },
    dispatch,
  );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps,
)(SelectedGunshotSection);

export const createIncident = (
  selectedRound: GunshotObservation,
  classification: ClassificationScore[],
  peak: { [_: string]: number[] },
  weaponTypes: { [_: string]: number },
  timingCategories: TimingCategories | undefined,
  distancePredictions?: DistancePredictions,
): Incident => {
  return {
    id: `${selectedRound.device.name}_${selectedRound.shot.index}`,
    organisationId: `${selectedRound.image.total_gunshots} shot${
      selectedRound.image.total_gunshots === 1 ? "" : "s"
    } in audio`,
    deviceId: selectedRound.device.name,
    tut: selectedRound.audio.tut * 1000000, // Tut-second to tut
    objectPath: createAudioObjectPath(selectedRound),
    classifications: classification ? classification : undefined,
    peaks: Object.keys(peak).length ? peak : undefined,
    weaponTypes: Object.keys(weaponTypes).length ? weaponTypes : undefined,
    peakCategories: timingCategories ? timingCategories : undefined,
    distancePredictions,
  };
};

const findPersistedManualSampleValue = (selectedRound: GunshotObservation) => {
  return selectedRound.manual &&
    selectedRound.manual.sample_number &&
    selectedRound.manual.sample_number != null
    ? [selectedRound.manual.sample_number]
    : [];
};

const findManualSonicBoomValue = (selectedRound: GunshotObservation) => {
  return selectedRound.manual_sonic_boom &&
    selectedRound.manual_sonic_boom.sample_number &&
    selectedRound.manual_sonic_boom.sample_number != null
    ? [selectedRound.manual_sonic_boom.sample_number]
    : [];
};

function getAndSetTimingIfAmmoAndTargetIsSet(
  selectedRound: GunshotObservation,
  jwtToken: string,
) {
  if (selectedRound.target) {
    getTimings(
      selectedRound.round.weather.speed_of_sound,
      selectedRound.device.position,
      selectedRound.target.position,
      selectedRound.device.position.master,
      selectedRound.shot.ammunition,
      jwtToken,
      (t) => store.dispatch(addTimingAction(t)),
    );
  }
}

function getDirectionPredictions(
  scoutsToUse: string[],
  devices: GunshotObservation[],
  jwtToken: string,
) {
  store.dispatch(addDirectionPredictionAction([], []));
  const devicesToUse = devices.filter(
    (s) =>
      scoutsToUse.findIndex((s2) => s.device.name === s2) > -1 &&
      s.manual &&
      s.manual !== null &&
      s.manual.sample_number &&
      s.manual.sample_number !== null,
  );
  if (devicesToUse.length > 1) {
    getDirectionPrediction(devicesToUse, jwtToken, (t) =>
      store.dispatch(
        addDirectionPredictionAction(
          t,
          devicesToUse.map((s) => s.device.name),
        ),
      ),
    );
  }
}

const createRelativePeaks = (selectedRound: GunshotObservation) => {
  const relativePeaks: {
    [_: string]: number[];
  } = {};

  const sonicBoomTimings =
    selectedRound.calculatedTiming &&
    selectedRound.calculatedTiming;
    if (sonicBoomTimings && sonicBoomTimings.maxSonicBoom.timeDifference > 0) {
      relativePeaks["sonicBoom"] = [-sonicBoomTimings.maxSonicBoom.timeDifference * 48000, -sonicBoomTimings.minSonicBoom.timeDifference * 48000];
    }
    // if (sonicBoomTimings && sonicBoomTimings.minSonicBoom.timeDifference > 0) {
    //   relativePeaks["minSonicBoom"] = [-sonicBoomTimings.minSonicBoom.timeDifference * 48000];
    // }
  // if (sonicBoomTimings && sonicBoomTimings.impact) {
  //   relativePeaks["impact"] = [
  //     (sonicBoomTimings.impact - sonicBoomTimings.gunshot) * 48000,
  //   ];
  // }
  if (selectedRound.audio.estimated_detection.sample_number) {
    relativePeaks["estimatedSample"] = [selectedRound.audio.estimated_detection.sample_number]
  }
  return relativePeaks;
};

const initManualSampleNumber = (
  observation: GunshotObservation,
): string | number | (() => React.ReactText) => {
  return observation.selectedSample && observation.selectedSample.sample_number
    ? observation.selectedSample.sample_number
    : "";
};
