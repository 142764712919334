import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GunshotObservation } from "../model/rounds";
import { ApplicationState } from "../reducers";
import {
  getTimingCategories,
  TimingCategories,
} from "../services/adminService";
import "./TimingCategory.css";
import "../round/Rounds.css";
import { cssColorFromBasedOnPercentage } from "../player/Player";
import { UpperRow } from "../layout/Row";
import styled from "styled-components";
import { showPeakCategoryAction } from "../round/roundReducer";
import { bindActionCreators, Dispatch } from "redux";


const Caliber = styled.div`
  margin-right: 1rem;
`

const TimingCategory = ({ selectedRound, jwtToken, showPeakCategories, setShowPeakCategories }: Props) => {
  const [timingCategories, setTimingCategories] = useState<
    TimingCategories | undefined
  >(undefined);
  useEffect(() => {
    setTimingCategories(undefined);
    if (
      jwtToken &&
      selectedRound &&
      selectedRound.selectedSample &&
      selectedRound.selectedSample.sample_number > 48 &&
      showPeakCategories
    ) {
      getTimingCategories(
        selectedRound,
        jwtToken,
        selectedRound.selectedSample.sample_number,
        (t) => setTimingCategories(t)
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRound, showPeakCategories]);
  if (!timingCategories || !showPeakCategories) {
    return <></>;
  }
  return (
    <div className={"TimingCategory-container"}>
      <div
        className={"Incident-peak-text TimingCategory-close"}
        onClick={() => setShowPeakCategories(false)}
      >
        CLOSE
      </div>
      <UpperRow>
        <Caliber>
          <Prediction
            title={"Weapon and Caliber"}
            predictions={timingCategories.weaponAndCaliber}
          />
        </Caliber>
        <div>
          <Prediction
            title={"Weapon type"}
            predictions={timingCategories.weaponType}
          />
          <Prediction
            title={"Distance"}
            predictions={timingCategories.distance}
          />
          <Prediction
            title={"SonicBoom?"}
            predictions={timingCategories.sonicBoom}
          />
          <Prediction title={"Angle"} predictions={timingCategories.angle} />
        </div>
      </UpperRow>
    </div>
  );
};

const Prediction = ({
  predictions,
  title,
}: {
  predictions: { [id: string]: number };
  title: string;
}) => {
  return (
    <>
      <p className={"Round-top-title"}>{title}</p>
      {Object.keys(predictions).map((k) => (
        <div className={"Incident-peak-text"}>
          {k}:
          <span
            style={{
              color: cssColorFromBasedOnPercentage(predictions[k] * 100),
            }}
          >
            {predictions[k].toFixed(1)}{" "}
          </span>
        </div>
      ))}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setShowPeakCategories: showPeakCategoryAction,
    },
    dispatch,
  );

  interface DispatchToProps {
    setShowPeakCategories: (_: boolean) => void;
  }

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  selectedRound: GunshotObservation | undefined;
  jwtToken: string | undefined;
  showPeakCategories: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    selectedRound: state.round.selectedRound
      ? state.round.selectedRound.observations.find((o) => {
          return o.device.name === state.round.selectedDevice;
        })
      : undefined,
    jwtToken: state.login.jwtToken,
    showPeakCategories: state.round.showPeakCategories,
  };
};

export default connect<StateToProps, {}, {}>(
  //@ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(TimingCategory);
