import React, { useState } from "react";
import { ApplicationState } from "../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import "./Rounds.css";
import {
  selectDeviceAction,
  selectRoundAction,
  DeviceFilter,
  filterDeviceAction,
  updateV0Action,
} from "./roundReducer";
import { GunshotObservation, RoundGunshots, Target } from "../model/rounds";
import ShotSelector from "./ShotSelector";
import AmmoOverlay from "./AmmoOverlay";
import { store } from "..";
import { updateUrl } from "./urlUtil";
import TargetOverlay from "./TargetOverlay";
import RoundSummaryStats from "./RoundSummaryStats";
import { tutToDate } from "../util";
import { formatDate } from "../player/IncidentItemInfo";

const Weather = ({ selectedRound }: { selectedRound: RoundGunshots }) => {
  const weather = selectedRound.observations[0].round.weather;
  return (
    <p className={"Round-item"}>
      Temp: {weather.air_temperature}°C • Speed of sound:{" "}
      {weather.speed_of_sound}
    </p>
  );
};

const RoundOverview = ({ selectedRound }: Props) => {
  if (selectedRound) {
    const round = selectedRound.observations[0].round;

    return (
      <div id={"round_overview"} className={["Round-overview"].join(" ")}>
        <div className={"Round-padding-top-bottom Round-padding-left-right"}>
          <h2 className={"Round-top-title"}>{round.name}</h2>
          <Weather selectedRound={selectedRound} />
          <RoundSummaryStats />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const ShotOverview = ({
  selectedRound,
  selectDevice,
  selectedDevice,
  suggestedTarget,
  jwtToken,
  deviceFilter,
  gunshotDirectionScouts,
  updateV0
}: Props) => {
  if (selectedRound) {
    return (
      <div
        id={"round_overview"}
        className={["Round-overview", "Round-top-bottom-padding"].join(" ")}
      >
        <ShotSelector selected={selectedRound} />
        <ShotInformation selectedRound={selectedRound} />
        <Weapon selectedRound={selectedRound} />
        <Ammo selectedRound={selectedRound} selectedDevice={selectedDevice} updateV0={updateV0} />
        {suggestedTarget && (
          <TargetOverlay
            jwtToken={jwtToken!}
            observation={selectedRound.observations[0]}
            target={suggestedTarget}
          />
        )}
        <Devices
          selectedRound={selectedRound}
          selectDevice={selectDevice}
          selectedDevice={selectedDevice}
          deviceFilter={deviceFilter}
          gunshotDirectionScouts={gunshotDirectionScouts}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

const Weapon = ({ selectedRound }: { selectedRound: RoundGunshots }) => {
  const weapon = selectedRound.observations[0].shot.weapon;
  return (
    <div className={"Round-padding-left-right"}>
      {/*<p className={"Round-subheader-span"}>Shot nr 1 of {round.total_shots}</p>*/}
      <div className={"Round-section"}>
        <a href={weapon.url} className={"Round-subheader"}>
          {weapon.name}
        </a>
        <p className={"Round-item"}>{weapon.type}</p>
      </div>
    </div>
  );
};

const ShotInformation = ({
  selectedRound,
}: {
  selectedRound: RoundGunshots;
}) => {
  const tut = selectedRound.observations[0].audio.peak_tut;

  if (!tut) {
    return <></>;
  }

  return (
    <div
      style={{ marginTop: "-1.5rem" }}
      className={"Round-padding-left-right"}
    >
      {/*<p className={"Round-subheader-span"}>Shot nr 1 of {round.total_shots}</p>*/}
      <div className={"Round-section"}>
        <p className={"Round-title"}>Gunshot timestamp</p>
        <p className={"Round-item"}>
          {formatDate(tutToDate(tut))} • Tut:{tut}
        </p>
      </div>
    </div>
  );
};

const Device = ({
  observation,
  selectedDevice,
  selectDevice,
  partOfDirectionCalculation,
}: {
  observation: GunshotObservation;
  selectDevice: (_: string | undefined) => void;
  selectedDevice: string | undefined;
  partOfDirectionCalculation: boolean;
}) => {
  const { device, manual } = observation;
  return (
    <div
      className={[
        "Round-small-section",
        "Round-padding-left-right",
        selectedDevice === device.name ? "selected" : "",
      ].join(" ")}
      onClick={() => {
        selectDevice(device.name);
        updateUrl(device.name);
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <p className={"Round-subsubheader"}>
          {device.name.substring(0, 10)}{" "}
          {partOfDirectionCalculation ? "🧭" : ""}
        </p>
        {manual !== undefined && manual !== null && !manual.unpersisted && (
          <span
            style={{
              fontSize: 11,
              fontWeight: "bold",
              color: manual.unadjustable
                ? "#CC0B06"
                : manual.low_accuracy
                ? "blue"
                : "#48BB78",
              letterSpacing: "1px",
            }}
          >
            {manual.unadjustable
              ? "AMBIGUOUS"
              : `CORRECTED ${manual.low_accuracy ? "(LOW ACCURACY)" : ""}`}
          </span>
        )}
      </div>
      <p className={"Round-item"}>
        {device.position.distance}m away • {device.position.sound_delay} sound
        delay
      </p>
    </div>
  );
};

const Ammo = ({
  selectedRound,
  selectedDevice,
  updateV0
}: {
  selectedRound: RoundGunshots;
  selectedDevice: string | undefined;
  updateV0: (_: number) => void;
}) => {
  const observattion =
    selectedRound.observations.find((o) => o.device.name === selectedDevice) ||
    selectedRound.observations[0];
  const ammo = observattion.shot.ammunition;
  const [openAmmoSettings, setOpenAmmoSettings] = useState(false);
  const bc = ammo && (ammo.bc || ammo.bc_doc);
  const v0 = ammo && (ammo.v0_calculated || ammo.v0 || ammo.v0_doc);
  return (
    <div className={"Round-padding-left-right"}>
      <p className={"Round-title"}>Ammunition</p>
      <div className={"Round-device-scroll-full-height"}>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setOpenAmmoSettings(true);
          }}
        >
          {openAmmoSettings && (
            <AmmoOverlay
              observation={observattion}
              jwtToken={store.getState().login.jwtToken!}
              updateV0={updateV0}
              closeCallback={() => setOpenAmmoSettings(false)}
            />
          )}
          {ammo ? (
            <>
              <p className={"Round-subheader"}>{ammo.description}</p>
              <p className={"Round-item"}>
                {ammo.size}
                {ammo.unit} • {ammo.weight}g • {bc}BC {ammo.v0_calculated ? `• ${ammo.v0_calculated}V0 manual`:""} {ammo.v0 ? `• ${ammo.v0}V0`:""} {ammo.v0_doc ? `• ${ammo.v0_doc}V0 doc`:""}
              </p>
            </>
          ) : (
            <p className={"Round-item"}>No ammo registered</p>
          )}
        </div>
      </div>
    </div>
  );
};

const Devices = ({
  selectedRound,
  selectedDevice,
  selectDevice,
  deviceFilter,
  gunshotDirectionScouts,
}: {
  selectedRound: RoundGunshots;
  selectDevice: (_: string | undefined) => void;
  selectedDevice: string | undefined;
  deviceFilter: DeviceFilter;
  gunshotDirectionScouts: string[];
}) => {
  return (
    <div>
      <div className={"Round-device-title-and-filter"}>
        <p className={"Round-title Round-padding-left-right"}>Devices</p>
        <div className={"Round-filters"}>
          <p
            className={
              deviceFilter === DeviceFilter.ALL ? "Round-selected" : ""
            }
            onClick={() => {
              store.dispatch(filterDeviceAction(DeviceFilter.ALL));
            }}
          >
            All
          </p>
          <p
            className={
              deviceFilter === DeviceFilter.NOT_CORRECTED
                ? "Round-selected"
                : ""
            }
            onClick={() => {
              store.dispatch(filterDeviceAction(DeviceFilter.NOT_CORRECTED));
            }}
          >
            Not corrected
          </p>
          <p
            className={
              deviceFilter === DeviceFilter.AMBIGUOUS ? "Round-selected" : ""
            }
            onClick={() => {
              store.dispatch(filterDeviceAction(DeviceFilter.AMBIGUOUS));
            }}
          >
            Ambiguous
          </p>
        </div>
      </div>
      <div
        className={
          selectedDevice
            ? "Round-device-scroll"
            : "Round-device-scroll-full-height"
        }
      >
        {selectedRound.observations
          .filter((g) => {
            switch (deviceFilter) {
              case DeviceFilter.NOT_CORRECTED:
                return g.manual === undefined || g.manual === null;
              case DeviceFilter.AMBIGUOUS:
                return (
                  g.manual !== undefined &&
                  g.manual !== null &&
                  g.manual.unadjustable
                );
              default:
                return true;
            }
          })
          .map((g) => (
            <Device
              key={`device_${g.device.name}`}
              observation={g}
              selectDevice={selectDevice}
              selectedDevice={selectedDevice}
              partOfDirectionCalculation={
                gunshotDirectionScouts.findIndex((s) => s === g.device.name) >
                -1
              }
            />
          ))}
      </div>
    </div>
  );
};

const RoundSummary = (props: Props) => {
  const { selectedRound } = props;
  if (selectedRound) {
    return (
      <>
        <RoundOverview {...props} />
        <ShotOverview {...props} />
      </>
    );
  } else {
    return <></>;
  }
};

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  selectedRound: RoundGunshots | undefined;
  jwtToken: string | undefined;
  selectedDevice: string | undefined;
  selectedRoundName: string | undefined;
  suggestedTarget: Target | undefined;
  deviceFilter: DeviceFilter;
  gunshotDirectionScouts: string[];
}

interface DispatchToProps {
  selectRound: (_: RoundGunshots) => void;
  selectDevice: (_: string | undefined) => void;
  updateV0: (_: number) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  selectedRound: state.round.selectedRound,
  jwtToken: state.login.jwtToken,
  selectedDevice: state.round.selectedDevice,
  selectedRoundName: state.round.selectedRoundName,
  suggestedTarget: state.round.suggestedTarget,
  deviceFilter: state.round.deviceFilter,
  gunshotDirectionScouts: state.round.gunshotDirectionScouts,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selectRound: selectRoundAction,
      selectDevice: selectDeviceAction,
      updateV0: updateV0Action
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(RoundSummary);
