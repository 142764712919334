import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import styled from "styled-components";
import LoadingAnimation from "../animation/LoadingAnimation";
import { createUrl } from "../audio/audioUtil";
import SecondaryButton from "../button/SecondaryButton";
import {
  IncidentList,
  queryForIncident,
  queryForPotentialIncidents,
} from "../firebase/potentialIncidents";
import Input from "../input/Input";
import { ColumnEnd } from "../layout/Column";
import {
  CenteredRow,
  FlexEndRow,
  FlexStartRow,
  SpaceBetweenRow,
  VerCenteredRow,
} from "../layout/Row";
import { SmallLogoWithDashboard } from "../logo/SmallLogo";
import { Incident, IncidentMessage, TimingSuggestion } from "../model/incident";
import { UserData } from "../model/user";
import OrganisationSummary from "../organisation/OrganisationSummary";
import { ApplicationState } from "../reducers";
import Player, { cssColorFromBasedOnPercentage } from "./Player";
import "./Player.css";
import { rawSpectrogramToRgbBytes } from "../util/spectrogramGenerator";
import IncidentItemInfo from "./IncidentItemInfo";
import BorderButton from "../button/BorderButton";
const FilteredOn = ({
  organisationId,
  deviceId,
}: {
  organisationId: string | undefined;
  deviceId: string | undefined;
}) => {
  return (
    <FlexEndRow>
      <VerCenteredRow>
        <h4 style={{ margin: 0, marginRight: "0.5rem" }}>Filtered by: </h4>
        {organisationId && (
          <SecondaryButton text={`Organisation ${organisationId}`} />
        )}
        {deviceId && (
          <SecondaryButton text={`Device ${deviceId.substr(0, 4)}`} />
        )}
      </VerCenteredRow>
    </FlexEndRow>
  );
};

const ClassificationCategory = styled.div`
  font-weight: bold;
  margin-left: 0.5rem;
  opacity: 0.8;
  /* font-size: 0.8rem; */
`;

const StyledTimingSuggestion = styled.div`
  border: 1px solid grey;
  border-radius: 2px;
  padding: 1rem 1rem 1.5rem 2rem;
  border: 1px solid #dae1e7;
  border-radius: 7px;
  margin-bottom: 1rem;
  .Organisation-key-value {
    margin-bottom: 0.125rem;
  }
`;

const TimingClassifications = styled.div`
  margin-top: 0.5rem;
`;

const TimingItem = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

function createIncidentListUrl(
  organisationId: string | undefined,
  deviceId: string | undefined,
  timeLimit: Date | undefined,
  lastDocument: string | undefined,
) {
  let newUrl = `/incidents?organisation-id=${organisationId}`;
  if (deviceId) {
    newUrl += `&device-id=${deviceId}`;
  }
  if (timeLimit) {
    newUrl += `&time=${timeLimit.toISOString()}`;
  }
  if (lastDocument) {
    newUrl += `&last-incident=${lastDocument}`;
  }
  return newUrl;
}

const TimingSuggestionBox = ({
  timingSuggestion,
}: {
  timingSuggestion: TimingSuggestion;
}) => {
  return (
    <StyledTimingSuggestion>
      <h4>{timingSuggestion.type}</h4>
      <div className={"Organisation-key-value"}>
        <p>Offset</p>
        <p>{timingSuggestion.offset}</p>
      </div>
      <div className={"Organisation-key-value"}>
        <p>Volume (dBFS)</p>
        <p>{timingSuggestion.volume.toFixed(0)}</p>
      </div>
      <div className={"Organisation-key-value"}>
        <p>Score</p>
        <p>{timingSuggestion.score}</p>
      </div>
      <TimingClassifications>
        {timingSuggestion.timingClassification.map((t) => {
          return (
            <TimingItem className={"Organisation-key-value"}>
              <p>{t.type}</p>
              {Object.keys(t.classification).map((c) => (
                <FlexStartRow>
                  <ClassificationCategory>{c}:</ClassificationCategory>
                  <Percentage percentage={t.classification[c]} />
                </FlexStartRow>
              ))}
            </TimingItem>
          );
        })}
      </TimingClassifications>
    </StyledTimingSuggestion>
  );
};

const Percentage = ({ percentage }: { percentage: number }) => {
  return (
    <p style={{ color: cssColorFromBasedOnPercentage(percentage * 100) }}>
      {(percentage * 100).toFixed(0)}%
    </p>
  );
};

const IncidentStats = ({ incident }: { incident: Incident }) => {
  const message: IncidentMessage = incident.message!;
  return (
    <div>
      <div className={"Organisation-key-value"}>
        <p>Krazy8</p>
        <Percentage percentage={message.gunshotDetectionResult} />
      </div>
      <FlexStartRow>
        {message.gunshotCount && (
          <div className={"Organisation-key-value"}>
            <p>Gunshot count</p>
            <p>{message.gunshotCount}</p>
          </div>
        )}
        {message.calculatedGunshotCount && (
          <div className={"Organisation-key-value"}>
            <p>Calculated gunshot count</p>
            <p>{message.calculatedGunshotCount}</p>
          </div>
        )}
        {message.firingRate && (
          <div className={"Organisation-key-value"}>
            <p>Firing rate</p>
            <p>{message.firingRate}</p>
          </div>
        )}

        {message.burstLength && (
          <div className={"Organisation-key-value"}>
            <p>Bust length</p>
            <p>{message.burstLength}</p>
          </div>
        )}
      </FlexStartRow>

      <FlexStartRow>
        <div className={"Organisation-key-value"}>
          <p>Amplitude</p>
          <p>
            {message.amplitude ? message.amplitude.toFixed(0) : "UNKNOWN"}dBfs
          </p>
        </div>
        <div className={"Organisation-key-value"}>
          <p>Noise level</p>
          <p>
            {message.noiseLevel ? message.noiseLevel.toFixed(0) : "UNKNOWN"}dBfs
          </p>
        </div>
      </FlexStartRow>
      <FlexStartRow>
        <div className={"Organisation-key-value"}>
          <p>Lat,Lon</p>
          <p>
            {message.latitude},{message.longitude}
          </p>
        </div>
      </FlexStartRow>

      <h3>Timing suggestions</h3>
      {message.timingSuggestions.map((t) => {
        return <TimingSuggestionBox timingSuggestion={t} />;
      })}
    </div>
  );
};

const IncidentItem = ({
  incident,
  jwtToken,
}: {
  incident: Incident;
  jwtToken: string;
}) => {
  console.log("INCIDENT", incident);
  return (
    <div className="Widget-item">
      <SpaceBetweenRow>
        {incident.message && <IncidentStats incident={incident} />}
      </SpaceBetweenRow>
      {incident.spectrogram !== undefined ? (
        <>
          <IncidentItemInfo key={incident.id} incident={incident} />
          <SpectrogramComponent spectrogram={incident.spectrogram} />
        </>
      ) : (
        <Player
          incident={incident}
          jwtToken={jwtToken}
          audioFilenameForPeakCategory={incident.objectPath}
          url={createUrl(incident.objectPath, "triangula-scout-audio")}
        />
      )}
    </div>
  );
};

const Meta = styled.div`
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2rem;
`;

const InputWrapper = styled.div`
  margin-top: 1rem;
`;

const OrganisationIncidentPage = (props: Props) => {
  const [incidents, setIncidents] = useState<Incident[] | undefined>(undefined);
  const [organisationId, setOrganisationId] = useState<string | undefined>(
    undefined,
  );
  const [deviceId, setDeviceId] = useState<string | undefined>(undefined);
  const [timeLimit, setTimeLimit] = useState<Date | undefined>(undefined);
  const [lastDocument, setLastDocument] = useState<string | undefined>(
    undefined,
  );

  const [startAfter, setStartAfter] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      //@ts-ignore
      const urlParams = new URLSearchParams(props.location.search);
      if (!organisationId) {
        setOrganisationId(urlParams.get("organisation-id") || undefined);
      }
      if (!timeLimit) {
        const t = urlParams.get("time");
        setTimeLimit(t ? new Date(t) : undefined);
      }
      const deviceId: string | undefined =
        urlParams.get("device-id") !== null
          ? urlParams.get("device-id")!
          : undefined;
      setDeviceId(deviceId);
      if (organisationId) {
        const lastIncident =
          urlParams.get("last-incident") !== null
            ? urlParams.get("last-incident")!
            : undefined;
        setIncidents(undefined);
        let lastDoc = lastIncident
          ? await queryForIncident(lastIncident)
          : undefined;

        queryForPotentialIncidents(
          organisationId,
          deviceId,
          timeLimit,
          lastDoc,
        ).then((i: IncidentList) => {
          setIncidents(i.incidents);
          setLastDocument(i.lastDocument);
        });
      }
    })();
    // eslint-disable-next-line
  }, [organisationId, timeLimit, startAfter]);

  let history = useHistory();
  const { jwtToken } = props;

  if (jwtToken) {
    return (
      <div className={"Dashboard"}>
        <div className={"Dashboard-header"}>
          <SmallLogoWithDashboard fontSizeRem={1.625} />
        </div>
        <Meta className={"Organisation-section"}>
          {organisationId && (
            <OrganisationSummary organisationId={organisationId} />
          )}

          {incidents !== undefined && (
            <ColumnEnd>
              <FilteredOn organisationId={organisationId} deviceId={deviceId} />
              <InputWrapper>
                <Input
                  placeholder={new Date().toISOString()}
                  value={timeLimit ? timeLimit.toISOString() : undefined}
                  onConfirm={(s) => {
                    setTimeLimit(new Date(s));
                    setLastDocument(undefined);
                    setStartAfter(undefined);
                    let newUrl = createIncidentListUrl(
                      organisationId,
                      deviceId,
                      new Date(s),
                      undefined,
                    );
                    history.push(newUrl);
                  }}
                  cssProps={{ width: "11rem" }}
                />
              </InputWrapper>
            </ColumnEnd>
          )}
        </Meta>
        <div className={"Incident-page"}>
          {incidents === undefined && (
            <LoadingAnimation height={50} width={50} />
          )}
          {incidents !== undefined && incidents.length > 0 && (
            <>
              {incidents.map((d) => (
                <IncidentItem incident={d} jwtToken={jwtToken} />
              ))}
              <div style={{ marginTop: "5rem", marginBottom: "10rem" }}>
                <BorderButton
                  text={"Load more"}
                  onClick={() => {
                    let newUrl = createIncidentListUrl(
                      organisationId,
                      deviceId,
                      timeLimit,
                      lastDocument,
                    );
                    if (lastDocument) {
                      setStartAfter(lastDocument);
                    }
                    history.push(newUrl);
                  }}
                />
              </div>
            </>
          )}

          {incidents !== undefined && incidents.length === 0 && (
            <CenteredRow>
              <h2>No incidents found</h2>
            </CenteredRow>
          )}
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

class SpectrogramComponent extends React.Component<
  { spectrogram: number[][] },
  {}
> {
  private canvasRef = React.createRef<HTMLCanvasElement>();

  componentDidMount() {
    // Retrieve the canvas element from the ref
    const canvas = this.canvasRef.current;
    const ctx = canvas!.getContext("2d");

    // Sample spectrogram data (replace with your data)
    const spectrogram: number[][] = this.props.spectrogram;
    // Set the desired canvas dimensions
    const canvasWidth = spectrogram[0].length * 5; // Adjust canvas width as needed
    const canvasHeight = spectrogram.length * 5; // Adjust canvas height as needed

    // Calculate the actual image dimensions based on spectrogram data
    const spectrogramWidth = spectrogram[0].length;
    const spectrogramHeight = spectrogram.length;

    // Set the canvas dimensions
    canvas!.width = canvasWidth;
    canvas!.height = canvasHeight;

    let rgbArray = rawSpectrogramToRgbBytes(spectrogram);
    // Fill the canvas with spectrogram data, centering it within the canvas
    for (let y = 0; y < spectrogramHeight; y++) {
      for (let x = 0; x < spectrogramWidth; x++) {
        ctx!.fillStyle = `rgb(${rgbArray[y][x][0]}, ${rgbArray[y][x][1]}, ${rgbArray[y][x][2]})`;
        ctx!.fillRect(x * 5, y * 5, 5, 5);
      }
    }
  }

  render() {
    return (
      <div style={{ paddingLeft: "20px" }}>
        <canvas ref={this.canvasRef} width="1000" height="1000"></canvas>
      </div>
    );
  }
}

interface OwnProps {}

interface StateToProps {
  filter: string | undefined;
  incidents: Incident[];
  jwtToken: string | undefined;
  userData: UserData | undefined;
  incidentForOverlay: Incident | undefined;
}

interface DispatchFromProps {}

interface Props extends StateToProps, DispatchFromProps, OwnProps {}

const mapStateToProps = (state: ApplicationState) => ({
  filter: state.incident.filter,
  jwtToken: state.login.jwtToken,
  userData: state.login.userData,
  incidentForOverlay: state.incident.incidentForOverlay,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

export default connect<StateToProps, DispatchFromProps>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps,
)(OrganisationIncidentPage);
