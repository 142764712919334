import React, { useState } from "react";
import { ApplicationState } from "../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { addFilterAction, OrganisationDetails } from "./incidentReducer";
import { Incident } from "../model/incident";
import { tutToDate } from "../util";
import moment from "moment";
import { store } from "..";

export const formatTimeDate = (date: Date) => moment(date).format("HH:mm:ss");

export const formatDate = (date: Date) =>
  moment(date).format("YYYY-MM-DD HH:mm:ss:SSS");

export const prettyTime = (date: Date) =>
  moment(date).startOf("second").fromNow();

const IncidentItemInfo = ({
  incident,
  filter,
  addFilter,
  organisation,
}: Props) => {
  const [readableTime, setReadableTime] = useState(
    tutToDate(incident.tut).toISOString(),
  );
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout | undefined>(
    undefined,
  );
  let organisationTagId = `organisation_${incident.organisationId}`;
  let deviceTagId = `device_${incident.deviceId}`;

  if (!timeoutRef) {
    setTimeoutRef(
      setTimeout(() => {
        setReadableTime(tutToDate(incident.tut).toISOString());
        setTimeoutRef(undefined);
      }, 10000),
    );
  }

  const deviceDetails =
    organisation &&
    organisation.devices.find((d) => d.deviceId === incident.deviceId);

  const selectedRound = store.getState().round.selectedRound;
  const selectedDevice = store.getState().round.selectedDevice;
  const observation =
    selectedRound !== undefined &&
    selectedDevice !== undefined &&
    selectedRound.observations.find((o) => o.device.name === selectedDevice);
  // Check if tut_verified is set if round analyzer is the one using this component
  return (
    <div className={"Incident-info"}>
      <div className={"Incident-main-controllers"}>
        {observation && observation.manual && (
          <div
            style={{
              color: observation.manual.low_accuracy
                ? "blue"
                : observation.manual.unadjustable
                  ? "red"
                  : "green",
              opacity: 0.7,

              marginRight: "0.5rem",
              fontWeight: "bold",
            }}
            className={"Round-updated-by"}
          >
            CORRECTED{" "}
            {observation.manual.low_accuracy
              ? " (LOW ACCURACY) "
              : observation.manual.unadjustable
                ? " (AMBIGUOUS) "
                : ""}
          </div>
        )}
        <div className={"Round-updated-by"}>
          {readableTime}(Megatut: {incident.tut / 1000000})
        </div>

        {observation ? (
          <>
            <div
              style={{
                color: observation.manual?.copilot === true ? "green" : "red",
                letterSpacing: "1.5px",
                marginLeft: "10px",
              }}
              className={"Round-updated-by"}
            >
              {observation.manual?.copilot === true
                ? "Corrected by Copilot"
                : "Not Corrected by Copilot"}
            </div>
            <div className={"Round-updated-by"} style={{ marginLeft: "10px" }}>
              {" "}
              Distance: {observation.device.position.distance}m away
            </div>
            <div className={"Round-updated-by"} style={{ marginLeft: "10px" }}>
              {" "}
              AngleFromGunshot: {observation.device.position.angle_to_gunshot}°
            </div>
          </>
        ) : (
          <div />
        )}
      </div>

      <div className={"Incident-tags"}>
        <div
          className={
            filter === organisationTagId
              ? "Incident-tag-filtered"
              : "Incident-tag"
          }
          onClick={() => {
            addFilter(organisationTagId);
          }}
        >
          {organisation && organisation.displayName + " | "}
          {incident.organisationId}
        </div>
        <div
          className={
            filter === deviceTagId ? "Incident-tag-filtered" : "Incident-tag"
          }
          onClick={() => {
            addFilter(deviceTagId);
          }}
        >
          {deviceDetails && deviceDetails.displayName + " | "}
          {incident.deviceId.substring(0, 7)}
        </div>
      </div>
    </div>
  );
};

interface OwnProps {
  incident: Incident;
}

interface StateToProps {
  filter: string | undefined;
  organisation: OrganisationDetails | undefined;
}

interface DispatchFromProps {
  addFilter: (_: string | undefined) => void;
}

interface Props extends StateToProps, DispatchFromProps, OwnProps {}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => ({
  filter: state.incident.filter,
  organisation: state.incident.organisations.find(
    (o) => o.organisationId === ownProps.incident.organisationId,
  ),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addFilter: addFilterAction,
    },
    dispatch,
  );

export default connect<
  StateToProps,
  DispatchFromProps,
  OwnProps,
  ApplicationState
>(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentItemInfo);
