import styled from "styled-components";

export const VerCenteredRow = styled.div`
  display: flex;
  align-items: center;
`;

export const CenteredRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UpperRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const SpaceBetweenRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FlexStartRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const WrappingRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const FlexSpaceBetweenRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FlexStartRowTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const FlexStartRowBottomColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: flex-bottom;
`;

export const FlexEndRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
