import React, { useEffect, useState } from "react";
import "./Player.css";
import WaveSurfer from "wavesurfer.js";
// @ts-ignore
import PlayIcon from "../icons/PlayIcon";
import { Incident } from "../model/incident";
import IncidentItemInfo from "./IncidentItemInfo";
import { tutToDate } from "../util";
// @ts-ignore
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min.js";
import { ClassificationScore, downloadFile, TimingCategories } from "../services/adminService";
import { store } from "..";
import { selectedSampleAction } from "../round/roundReducer";
import { setSelectedIncidentAction } from "./incidentReducer";
import {
  A_KEY,
  D_KEY,
  R_KEY,
  N_KEY,
  L_KEY,
  ONE_KEY,
  TWO_KEY,
  THREE_KEY,
  SPACE_KEY,
  G_KEY,
  E_KEY,
  DOT_KEY,
  F_KEY,
} from "../shortcut/keys";
// @ts-ignore
window.WaveSurfer = require("wavesurfer.js");

interface State {
  waveSurfer?: WaveSurfer;
  currentTime: number;
  currentTimeSamples: number;
  slider: number;
  loaded: string;
  attemptsToFetch: number;
  ready: boolean;
  error: boolean;
  peakFilter: string[];
  startedTime: number;
  keepZoom: boolean;
  initialTimingSet: boolean;
  useFilter: boolean;
  currentUrl: string;
  peakCategories?: TimingCategories;
  playing: boolean;
}

interface Props {
  altId?: string;
  incident: Incident;
  jwtToken: string;
  url: string;
  width?: string;
  additionalPeaks?: { [id: string]: number[] };
  relativePeaks?: { [id: string]: number[] };
  sonicBoomMode?: boolean;
  onCursorChange?: (timesample: number) => void;
  audioFilenameForPeakCategory?: string;
  copilot?: boolean;
  persistedSample?: number;
  samplePrecision?: number;
  tut?: number;
}

const ifDefined = (waveSurfer: WaveSurfer | undefined, callback: (_: WaveSurfer) => void) => {
  if (waveSurfer !== undefined) {
    callback(waveSurfer);
  }
};

export const FADE_COLORS = [
  "#a6030055",
  "#ffa50055",
  "#0000cc55",
  "rgba(23,166,0,0.33)",
  "rgba(166,0,164,0.33)",
  "#a55e0055",
  "#0000ff55",
];

const SONIC_BOOM_COLOR = "#3887be";
export const RELATIVE_FADE_COLORS = ["#006637"];

export const LOW_OPACITY_RED = ["#FF573322"];

const updateRegions = (
  peaks: { [id: string]: number[] } | undefined,
  relativePeaks: { [id: string]: number[] } | undefined,
  currentTimeSamples: number,
  waveSurfer: WaveSurfer,
  filter: string[],
  persistedSample?: number,
  copilot?: boolean,
  copilotSamplePrecision?: number
) => {
  waveSurfer.clearRegions();

  const duration = waveSurfer.getDuration();

  if (peaks) {
    Object.keys(peaks)
      .map((p, i) => [p, FADE_COLORS[i]])
      .filter((p) => filter.find((p2) => p2 === p[0]))
      .forEach((p) => createRegions(peaks[p[0]], duration, p[1], waveSurfer));
  }

  if (waveSurfer && waveSurfer.getDuration() === 1.5) {
    createRegionForLastHalfSecond(waveSurfer);
  }
  if (relativePeaks) {
    createSonicBoomRegion(currentTimeSamples, relativePeaks, `${SONIC_BOOM_COLOR}30`, waveSurfer);
  }
  if (persistedSample && copilot && copilotSamplePrecision) {
    createCopilotRegion(persistedSample, copilotSamplePrecision, LOW_OPACITY_RED[0], waveSurfer);
  }
};

const createRegions = (peaks: number[], duration: number, color: string, waveSurfer: WaveSurfer) => {
  peaks.forEach((peak) => {
    waveSurfer.addRegion({
      start: peak / 48000,
      end: peak / 48000 + duration * 0.003,
      color: color,
      drag: false,
      resize: false,
    });
  });
};

const createCopilotRegion = (sample: number, precision: number, color: string, waveSurfer: WaveSurfer) => {
  waveSurfer.addRegion({
    start: (sample - precision / 2) / 48000,
    end: (sample + precision / 2) / 48000,
    color: color,
    drag: false,
    resize: false,
  });
};

const createSonicBoomRegion = (
  currentTimeSamples: number,
  relativePeaks: { [id: string]: number[] },
  color: string,
  waveSurfer: WaveSurfer
) => {
  const sonicBoomRange = relativePeaks?.["sonicBoom"];
  if (sonicBoomRange?.length === 2) {
    waveSurfer.addRegion({
      start: (currentTimeSamples + sonicBoomRange[0]) / 48000,
      end: (currentTimeSamples + sonicBoomRange[1]) / 48000,
      color: color,
      drag: false,
      resize: false,
    });
  }
};

const AudioStatusMessage = ({ ready, error }: State) => (
  <>{!ready && error && <div className={"Incident-loading-status"}>Error loading audio</div>}</>
);

interface PropsAndState extends State, Props {}

interface PeakLabelsProps extends PropsAndState {
  updateFilter: (_: string) => void;
  currentTimeSamples: number;
}

function createRegionForLastHalfSecond(waveSurfer: WaveSurfer) {
  waveSurfer.addRegion({
    start: 1,
    end: 1.5,
    color: LOW_OPACITY_RED,
    drag: false,
    resize: false,
  });
}

function PeakLabels({
  ready,
  incident,
  peakFilter,
  updateFilter,
  waveSurfer,
  additionalPeaks,
  relativePeaks,
  currentTimeSamples,
  persistedSample,
  copilot,
  samplePrecision,
}: PeakLabelsProps) {
  const peaks = {
    ...(incident.peaks !== undefined ? incident.peaks : {}),
    ...(additionalPeaks !== undefined ? additionalPeaks : {}),
  };

  return (
    <PeakInfo
      peaks={peaks}
      peakFilter={peakFilter}
      setPeakFilter={(s) => {
        updateFilter(s);
        updateRegions(
          peaks,
          relativePeaks,
          currentTimeSamples,
          waveSurfer!,
          peakFilter,
          persistedSample,
          copilot,
          samplePrecision
        );
      }}
    />
  );
}

const ClassificationAndWeaponTypes = ({
  incident,
  timingCategories,
  relativePeaks,
}: {
  incident: Incident;
  timingCategories: TimingCategories | undefined;
  relativePeaks: { [id: string]: number[] };
}) => {
  return (
    <div
      style={{ display: "flex", marginBottom: "1rem" }}
      id={`classifications`}
      className={"Incident-classification-info"}>
      {/* <div style={{ marginRight: "10px" }}>
      <WeaponTypeInformation classifications={timingCategories} />
    </div>
    |
    <div style={{ marginRight: "10px" }}>
      <WeaponTypeInformation classifications={timingCategories} />
    </div> */}
      {incident.classifications?.length ? (
        <>
          {getTextForRelativePeaks(relativePeaks)}
          <ClassificationInformation scores={incident.classifications} />
        </>
      ) : (
        <div className={"Incident-peak-text"}>Loading</div>
      )}
    </div>
  );
};

const Distances = ({ incident }: { incident: Incident }) => {
  const distances = incident.distancePredictions;
  if (distances !== undefined) {
    return (
      <div style={{ display: "flex" }}>
        <div className={"Incident-peak-text"}>Predicted distance:</div>
        <div style={{ marginRight: "10px" }}>
          <div id={`distances`} className={"Incident-classification-info"}>
            {distances.ranges.map((distance) => {
              const probability = distance.prediction * 100;
              return (
                <div
                  key={`${distance.from}_label`}
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}>
                  <div className={"Incident-peak-text"}>
                    {`${distance.from}m-${distance.to}m`}:{" "}
                    <span
                      style={{
                        color: cssColorFromBasedOnPercentage(probability),
                      }}>
                      {probability.toFixed(0)}%
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

class Player extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentTime: 0,
      currentTimeSamples: 0,
      slider: 0,
      loaded: "",
      initialTimingSet: false,
      attemptsToFetch: 0,
      ready: false,
      error: false,
      peakFilter: [],
      startedTime: 0,
      keepZoom: false,
      useFilter: false,
      currentUrl: "",
      playing: false,
    };
    this.onKeypress = this.onKeypress.bind(this);
    this.setCursor = this.setCursor.bind(this);
  }

  public componentDidMount() {
    const HEIGHT = 200;

    this.setState((prev) => ({
      ...prev,
      waveSurfer: WaveSurfer.create({
        container: `#waveform_${this.props.altId ? this.props.altId : this.props.incident.id.replace("+", "_")}`,
        mediaControls: true,
        height: HEIGHT,
        maxCanvasWidth: 500,
        minPxPerSec: 1,
        forceDecode: true,
        scrollParent: true,
        waveColor: "rgba(83,198,140,0.52)",
        progressColor: "#203864",
        responsive: true,
        xhr: {
          requestHeaders: [{ key: "Authorization", value: `Bearer ${this.props.jwtToken}` }],
        },
        plugins: [
          RegionsPlugin.create({
            regions: [],
          }),
        ],
      }),
    }));
    setTimeout(() => {
      this.state.waveSurfer!.on("ready", () => {
        if (!this.state.initialTimingSet) {
          this.setCursorToManualOrEstimated();
          this.setState((prev) => ({ ...prev, initialTimingSet: true }));
        } else {
          this.setCursor(this.state.currentTimeSamples);
        }

        setTimeout(() => {
          this.setIncidentToReady();
        }, 100);
      });

      // this.state.waveSurfer!.backend.setFilter(
      //   this.state.waveSurfer!.backend.ac.createBiquadFilter()
      // );
      // this.state.waveSurfer!.drawBuffer();

      this.state.waveSurfer!.on("finish", () => {
        setTimeout(() => this.setState((prev) => ({ ...prev, playing: false })), 100);
        if (this.state.startedTime) {
          this.state.waveSurfer!.seekAndCenter(this.state.startedTime / this.state.waveSurfer!.getDuration());
        }
      });
      this.state.waveSurfer!.on("play", () => {
        setTimeout(() => this.setState((prev) => ({ ...prev, playing: true })), 100);
        this.setState((prev) => ({
          ...prev,
          startedTime: this.state.currentTime,
        }));
      });

      this.state.waveSurfer!.on("interaction", (e) => {
        this.updateCurrentTime();
        // If the interaction is either:
        // - Interaction while still playing
        // - Interaction is the play button
        // we don't want to update the cursor or the sample number
        if (this.state.playing || e.toString().includes(".play(")) {
          return;
        }
        if (this.state.ready) {
          setTimeout(() => {
            const additionalPeaks = this.props.additionalPeaks;
            if (
              additionalPeaks &&
              additionalPeaks["Manual"] &&
              additionalPeaks["Manual"].length &&
              this.state.currentTimeSamples === additionalPeaks["Manual"][0]
            ) {
              return;
            }
            this.props.onCursorChange && this.props.onCursorChange(this.state.currentTimeSamples);
          }, 200);
        } else {
        }
        //
      });
      this.state.waveSurfer!.on("error", () => {
        if (this.state.attemptsToFetch <= 5) {
          setTimeout(() => {
            this.load();
            this.setState((prev) => ({
              ...prev,
              attemptsToFetch: prev.attemptsToFetch + 1,
            }));
          }, 5000);
        } else {
          this.setState((prev) => ({ ...prev, error: true }));
        }
        //
      });

      this.state.waveSurfer!.on("audioprocess", () => {
        if (this.state.waveSurfer!.isPlaying()) {
          this.setState((prev) => ({
            ...prev,
            currentTime: this.state.waveSurfer!.getCurrentTime(),
            currentTimeSamples: this.state.waveSurfer!.getCurrentTime() * 48000,
          }));
          //
        }
      });

      document.addEventListener("keydown", this.onKeypress);

      if (Date.now() - tutToDate(this.props.incident.tut).getTime() < 5000) {
        setTimeout(() => {
          this.load();
        }, 10000);
      } else {
        this.load();
      }
      this.setState((prev) => ({
        ...prev,
        loaded: this.props.incident.id,
        currentUrl: this.props.url,
      }));
    }, 1000);
  }

  private onKeypress(e: KeyboardEvent) {
    if (store.getState().round.inputFieldInFocus) {
      return;
    }

    if (A_KEY === e.keyCode) {
      this.moveCursor(e.shiftKey ? -10 : -1);
      updateCachedManualTiming(this.state.currentTimeSamples);
    } else if (D_KEY === e.keyCode) {
      this.moveCursor(e.shiftKey ? 10 : 1);
      updateCachedManualTiming(this.state.currentTimeSamples);
    } else if (R_KEY === e.keyCode) {
      const peaks = this.props.additionalPeaks;
      if (peaks && peaks.Estimated && peaks.Estimated.length) {
        setTimeout(() => {
          this.setCursor(peaks.Estimated[0] + store.getState().round.estimatedToManualDiff);
        }, 200);
      }
    } else if (N_KEY === e.keyCode) {
      normalize(this.state.waveSurfer, () => this.load());
    } else if (L_KEY === e.keyCode) {
      this.toggleKeepZoom();
    } else if (ONE_KEY === e.keyCode) {
      ifDefined(this.state.waveSurfer, (w) => w.zoom(0));
    } else if (TWO_KEY === e.keyCode) {
      ifDefined(this.state.waveSurfer, (w) => w.zoom(11000));
    } else if (THREE_KEY === e.keyCode) {
      ifDefined(this.state.waveSurfer, (w) => w.zoom(22000));
    } else if (SPACE_KEY === e.keyCode) {
      ifDefined(this.state.waveSurfer, (w) => w.play());
    } else if (F_KEY === e.keyCode) {
      this.toggleUseFilter();
    } else if (G_KEY === e.keyCode) {
      if (this.props.incident.peaks && this.props.incident.peaks["Gale"] && this.props.incident.peaks["Gale"].length) {
        this.setCursor(this.props.incident.peaks["Gale"][0]);
      }
    } else if (E_KEY === e.keyCode) {
      if (this.props.incident.peaks && this.props.incident.peaks["Ed"] && this.props.incident.peaks["Ed"].length) {
        this.setCursor(this.props.incident.peaks["Ed"][0]);
      }
    } else if (DOT_KEY === e.keyCode) {
      this.setCursor(1);
    }
  }

  manualTimerSubmit = () => {};

  private setCursorToManualOrEstimated() {
    const additionalPeaks = this.props.additionalPeaks;
    if (
      this.props.sonicBoomMode &&
      additionalPeaks &&
      additionalPeaks["ManualSonicBoom"] &&
      additionalPeaks["ManualSonicBoom"].length
    ) {
      this.seekToAndCenter(additionalPeaks["ManualSonicBoom"][0]);
    } else if (additionalPeaks && additionalPeaks["Manual"] && additionalPeaks["Manual"].length) {
      this.seekToAndCenter(additionalPeaks["Manual"][0]);
    } else if (additionalPeaks && additionalPeaks["Estimated"] && additionalPeaks["Estimated"].length) {
      this.state.waveSurfer!.seekAndCenter(
        additionalPeaks["Estimated"][0] / 48000 / this.state.waveSurfer!.getDuration()
      );
    }
  }

  private seekToAndCenter(sample: number) {
    const seekValue = sample / 48000 / this.state.waveSurfer!.getDuration();
    if (seekValue < 1 && seekValue > 0) {
      this.state.waveSurfer!.seekAndCenter(seekValue);
    }
  }

  private load() {
    try {
      let uri = `${this.props.url.replace("+", "%2B")}&filter=${this.state.useFilter}`;
      // Encoding '+' char because it ends up being space if not.
      this.state.waveSurfer!.load(uri);
    } catch (e) {}
  }

  private updateCurrentTime() {
    setTimeout(() => {
      this.setState((prev) => ({
        ...prev,
        currentTime: this.state.waveSurfer!.getCurrentTime(),
        currentTimeSamples: this.state.waveSurfer!.getCurrentTime() * 48000,
      }));
    }, 200);
  }

  private moveCursor(samplesOffset: number) {
    const newSample = this.state.currentTimeSamples + samplesOffset;
    this.setState((prev) => ({
      ...prev,
      currentTime: newSample / 48000,
      currentTimeSamples: newSample,
    }));
    this.seekToAndCenter(newSample);
  }

  private setCursor(sample: number) {
    this.seekToAndCenter(sample);
  }

  private setIncidentToReady() {
    this.setState((prev) => ({
      ...prev,
      ready: true,
      error: false,
    }));

    this.updatePeak();
  }

  private updatePeak() {
    if (this.props.incident.peaks && this.state.waveSurfer!.getDuration()) {
      const peaks =
        this.props.incident.peaks && this.props.additionalPeaks
          ? { ...this.props.incident.peaks, ...this.props.additionalPeaks }
          : this.props.incident.peaks;
      updateRegions(
        peaks,
        this.props.relativePeaks,
        this.state.currentTimeSamples,
        this.state.waveSurfer!,
        this.state.peakFilter,
        this.props.persistedSample,
        this.props.copilot,
        this.props.samplePrecision
      );
    } else {
      setTimeout(() => {
        this.updatePeak();
      }, 1000);
    }
  }

  // Make sure we un-register Firebase observers when the component unmount.
  public componentWillUnmount() {
    this.state.waveSurfer!.destroy();

    document.removeEventListener("keydown", this.onKeypress);
  }

  render() {
    if (this.state.waveSurfer && !this.state.waveSurfer.isPlaying()) {
      setTimeout(() => this.updatePeak(), 100);
    }

    if (this.props.url !== this.state.currentUrl && this.state.loaded === this.props.incident.id) {
      if (this.state.currentUrl !== "") {
        this.load();
        this.setState((prev) => ({ ...this.state, currentUrl: this.props.url }));
      }
    }
    if (this.state.loaded && this.state.loaded !== this.props.incident.id) {
      this.setState((prev) => ({
        ...prev,
        loaded: this.props.incident.id,
        attemptsToFetch: 0,
        ready: false,
        error: false,
        startedTime: 0,
        currentUrl: this.props.url,
        initialTimingSet: false,
      }));
      // this.state.waveSurfer!.params.normalize = false;
      if (!this.state.keepZoom) {
        this.state.waveSurfer!.zoom(0);
      }
      updateRegions(
        {},
        {},
        this.state.currentTimeSamples,
        this.state.waveSurfer!,
        this.state.peakFilter,
        this.props.persistedSample,
        this.props.copilot,
        this.props.samplePrecision
      );

      this.load();
    }
    return (
      <div className={"Incident-item-relative"}>
        <AudioStatusMessage {...this.state} />
        <PeakLabels
          {...this.state}
          {...this.props}
          updateFilter={this.createFilter()}
          currentTimeSamples={this.state.currentTimeSamples}
        />
        <div
          style={{ width: this.props.width ? this.props.width : "1200px" }}
          className={["Incident-item", this.lessThan1minOld() ? "Incident-fade" : ""].join(" ")}>
          <IncidentItemInfo key={this.props.incident.id} incident={this.props.incident} />
          <div
            id={`waveform_${this.props.altId ? this.props.altId : this.props.incident.id.replace("+", "_")}`}
            className={"Incident-main-wave"}
          />
          <Controller
            waveSurfer={this.state.waveSurfer}
            currentTimeSamples={this.state.currentTimeSamples}
            currentTime={this.state.currentTime}
            ready={this.state.ready}
            incident={this.props.incident}
            jwtToken={this.props.jwtToken}
            url={this.props.url}
            load={() => this.load()}
            keepZoom={this.state.keepZoom}
            keepZoomToggle={() => this.toggleKeepZoom()}
            useFilter={this.state.useFilter}
            filterToggle={() => this.toggleUseFilter()}
            relativePeaks={this.props.relativePeaks ? this.props.relativePeaks : {}}
            timingCategories={this.state.peakCategories}
          />
        </div>
      </div>
    );
  }

  private toggleKeepZoom(): void {
    return this.setState((prev) => ({ ...prev, keepZoom: !prev.keepZoom }));
  }

  private toggleUseFilter(): void {
    this.setState((prev) => ({ ...prev, useFilter: !prev.useFilter }));

    setTimeout(() => this.load(), 100);
  }

  private createFilter() {
    return (filter: string) => {
      this.setState((prev) => {
        return {
          ...prev,
          peakFilter: prev.peakFilter.find((p) => p === filter)
            ? prev.peakFilter.filter((p) => p !== filter)
            : [...prev.peakFilter, filter],
        };
      });
    };
  }

  private lessThan1minOld() {
    return Date.now() - tutToDate(this.props.incident.tut).getTime() < 60000;
  }
}

interface ControllerProps {
  waveSurfer: WaveSurfer | undefined;
  currentTime: number;
  currentTimeSamples: number;
  ready: boolean;
  incident: Incident;
  url: string;
  jwtToken: string;
  load: () => void;
  keepZoom: boolean;
  keepZoomToggle: () => void;
  useFilter: boolean;
  filterToggle: () => void;
  relativePeaks: { [id: string]: number[] };
  timingCategories: TimingCategories | undefined;
}

const DownloadFile = ({ jwtToken, incident, url }: { incident: Incident; url: string; jwtToken: string }) => {
  return (
    <p
      className={"Incident-download-link"}
      onClick={() => {
        downloadFile(incident, url, jwtToken);
      }}>
      Download file
    </p>
  );
};

const MarkAsNotGunshot = ({ jwtToken, incident, url }: { incident: Incident; url: string; jwtToken: string }) => {
  return (
    <p
      className={"Incident-download-link"}
      onClick={() => {
        store.dispatch(setSelectedIncidentAction(incident));
      }}>
      Mark as not gunshot
    </p>
  );
};

const Expanded = ({ jwtToken, incident, url }: { incident: Incident; url: string; jwtToken: string }) => (
  <div className={"Incident-expanded"}>
    <DownloadFile incident={incident} url={url} jwtToken={jwtToken} />
    <div style={{ marginLeft: "10px" }}>
      <MarkAsNotGunshot incident={incident} url={url} jwtToken={jwtToken} />
    </div>
  </div>
);

const getTextForRelativePeaks = (relativePeaks: { [id: string]: number[] }) => {
  return (
    <div
      className={"Incident-peak-text"}
      style={{
        marginRight: "2rem",
      }}>
      {Object.keys(relativePeaks).map((s, i) => {
        const color = s === "sonicBoom" ? SONIC_BOOM_COLOR : RELATIVE_FADE_COLORS[i];
        return (
        <span style={{ color: color, marginLeft: "0.5rem" }}>{
          `${s}: ${relativePeaks[s].map((r) => r.toFixed()+"(" +(r / 48).toFixed()+"ms)").join(" to ")}`}</span>
      )})}
    </div>
  );
};

const Controller = ({
  waveSurfer,
  currentTimeSamples,
  currentTime,
  ready,
  incident,
  url,
  jwtToken,
  load,
  keepZoom,
  keepZoomToggle,
  useFilter,
  filterToggle,
  relativePeaks,
  timingCategories,
}: ControllerProps) => {
  const [slider, setSlider] = useState(0);
  useEffect(() => {
    if (!keepZoom) {
      setSlider(0);
    }
  }, [incident.id, keepZoom]);
  return (
    <div className={"Incident-controller"} style={{ opacity: ready ? 1 : 0.6 }}>
      <div className={"Incident-main-controllers"}>
        <PlayButton onClicked={() => ifDefined(waveSurfer, (w) => w.play())} />
        <div className={"Incident-slider"}>
          <input
            id="slider"
            data-action="zoom"
            type="range"
            min="0"
            max="22000"
            value={slider}
            style={{ width: "100px" }}
            onChange={(event) => {
              const newValue = +event.target.value;
              setSlider(newValue);
              ifDefined(waveSurfer, (w) => w.zoom(newValue));
            }}
          />
        </div>
        {waveSurfer && (
          <div
            className={waveSurfer!.params.normalize ? "Incident-tag-filtered" : "Incident-tag"}
            onClick={() => normalize(waveSurfer, load)}>
            {waveSurfer!.params.normalize ? (
              <span>
                <u>N</u>ormalized
              </span>
            ) : (
              <span>
                <u>N</u>ormalize
              </span>
            )}
          </div>
        )}

        <div
          className={keepZoom ? "Incident-tag-filtered" : "Incident-tag"}
          onClick={() => {
            keepZoomToggle();
          }}>
          <u>L</u>ock zoom
        </div>
        <div
          className={useFilter ? "Incident-tag-filtered" : "Incident-tag"}
          onClick={() => {
            filterToggle();
          }}>
          Use <u>f</u>ilter
        </div>
      </div>
      <div className={"Incident-right-details"}>
        <ClassificationAndWeaponTypes
          incident={incident}
          timingCategories={timingCategories}
          relativePeaks={relativePeaks}
        />
        <Distances incident={incident} />
        <div className={"Incident-sample"} onClick={() => updateCachedManualTiming(currentTimeSamples)}>
          {currentTimeSamples.toFixed(0)} samples ({currentTime.toFixed(2)} seconds)
        </div>
        <Expanded incident={incident} url={url} jwtToken={jwtToken} />
      </div>
    </div>
  );
};

export const cssColorFromBasedOnPercentage = (percent: number) => {
  const green = 120;
  const red = 0;
  const b = (green - red) * (percent / 100);
  const c = b + red;

  // Return a CSS HSL string
  return "hsl(" + c + ", 90%, 40%)";
};

const PeakInfo = ({
  peaks,
  peakFilter,
  setPeakFilter,
}: {
  peaks: { [id: string]: number[] };
  peakFilter: string[];
  setPeakFilter: (_: string) => void;
}) => {
  return (
    <div className={"Incident-peak-info"}>
      {Object.keys(peaks)
        // .filter((p) => incident.peaks![p].length)
        .map((p, i) => {
          return (
            <div
              key={`${p}_label`}
              className={"tooltip"}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: "10px",
                opacity: peakFilter.find((p2) => p2 === `${p}`) ? 1 : 0.5,
              }}
              onClick={() => {
                setPeakFilter(`${p}`);
              }}>
              <div
                style={{
                  color: FADE_COLORS[i],
                  background: FADE_COLORS[i],
                  width: "10px",
                  height: "10px",
                  borderRadius: "5px",
                  marginRight: "5px",
                }}
              />
              <div className={"Incident-peak-text"}>{p}</div>
              <span className={"tooltiptext"}>
                {peaks[p].length ? peaks[p].map((i) => `Sample: ${i}`).join(" | ") : "No peak"}
              </span>
            </div>
          );
        })}
    </div>
  );
};

const ClassificationInformation = ({ scores }: { scores: ClassificationScore[] }) => {
  return (
    <>
      {scores.map((p) => {
        return (
          <div
            className={"Incident-peak-text"}
            style={{
              marginLeft: "0.5rem",
            }}>
            {p.name}: <span style={{ color: cssColorFromBasedOnPercentage(p.value) }}>{p.value}%</span>
          </div>
        );
      })}
    </>
  );
};

const WeaponTypeInformation = ({ classifications }: { classifications: TimingCategories | undefined }) => {
  if (!classifications) {
    return <></>;
  }
  return (
    <div id={`classifications`} className={"Incident-classification-info"}>
      {Object.keys(classifications!).map((gunType) => {
        const probability = classifications.weaponType![gunType] * 100;
        return (
          <div
            key={`${gunType}_label`}
            style={{
              cursor: "pointer",
              marginLeft: "10px",
            }}>
            <div className={"Incident-peak-text"}>
              {gunType}:{" "}
              <span style={{ color: cssColorFromBasedOnPercentage(probability) }}>{probability.toFixed(1)}%</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const PlayButton = ({ onClicked }: { onClicked: () => void }) => (
  <div onClick={onClicked}>
    <PlayIcon />
  </div>
);

const PlayWrapper = (props: Props) => {
  const [incident, setIncident] = useState(props.incident.id);
  useEffect(() => {
    setIncident(props.incident.id);
  }, [incident, props.incident.id, props.additionalPeaks, props.tut]);

  return <Player {...props} />;
};

export default PlayWrapper;

function normalize(waveSurfer: WaveSurfer | undefined, load: () => void): void {
  return ifDefined(waveSurfer, (w) => {
    w.params.normalize = !w.params.normalize;

    load();
  });
}

function updateCachedManualTiming(currentTimeSamples: number) {
  store.dispatch(
    selectedSampleAction({
      author: "Not saved",
      sampleNumber: parseInt(currentTimeSamples.toFixed(0)),
      tut: 0,
      unpersisted: true,
    })
  );
}
