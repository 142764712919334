import React, { useEffect, useState } from "react";
import { getDistinctAmmos } from "../services/roundsService";
import { GunshotObservation, Ammunition } from "../model/rounds";
import { CustomInputField } from "./CustomInputField";
import { CenterDescription } from "../layout/Text";
import { persistV0, suggestV0Timings } from "../services/adminService";
import { setSourceMapRange } from "typescript";
import { FlexStartRow } from "../layout/Row";

const AmmoOverlay = ({
  jwtToken,
  observation,
  updateV0,
  closeCallback,
}: {
  jwtToken: string;
  observation: GunshotObservation;
  updateV0: (v0: number) => void;
  closeCallback: () => void;
}) => {
  const [observationId, setObservationId] = useState(observation.shot.index);
  const [sonicBoomSampleNumber, setSonicBoomSampleNumber] = useState(0);
  const [gunshotSampleNumber, setGunshotSampleNumber] = useState(0);
  const [suggestedV0, setSuggestedV0] = useState<number | undefined>(undefined);

  //@ts-ignore
  useEffect(() => {
    if (observation.shot.index !== observationId) {
      setObservationId(observation.shot.index);
      setSonicBoomSampleNumber(0);
      setGunshotSampleNumber(0);
    }
    const escapeKeyHandler = (e: KeyboardEvent) => {
      const ESCAPE_KEY = 27;
      if (ESCAPE_KEY === e.keyCode) {
        closeCallback();
      }
    };

    document.addEventListener("keydown", escapeKeyHandler);
    return () => document.removeEventListener("keydown", escapeKeyHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observation, observationId, closeCallback]);

  return (
    <div
      className={"Round-image-overlay"}
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
      <div className={"Round-title"}>Ammunition</div>
      <h2 className={"Round-subheader"}>
        {observation.shot.weapon.name} • {observation.shot.ammunition?.size}
      </h2>
      <CenterDescription>
        Calculate and store V0 based on difference between sonic boom and muzzle blast
      </CenterDescription>
      <div style={{ display: "flex", margin: "1rem" }}>
        <div>
          <div className={"Round-label"}>SonicBoom sample number</div>
          <CustomInputField
            className={"Round-subheader Round-ammo-input"}
            style={{ width: "10rem" }}
            value={"" + sonicBoomSampleNumber}
            onChange={(newValue) => {
              setSonicBoomSampleNumber(+newValue);
            }}
          />
        </div>
        <div>
          <div className={"Round-label"}>Muzzle sample number</div>
          <CustomInputField
            className={"Round-subheader Round-ammo-input"}
            style={{ width: "10rem" }}
            value={"" + gunshotSampleNumber}
            onChange={(newValue) => {
              setGunshotSampleNumber(+newValue);
            }}
          />
        </div>
      </div>
      {suggestedV0 ? (
        <h2 className={"Round-subheader"}>Suggested V0: {suggestedV0}</h2>
      ) : (
        <h2 className={"Round-subheader"}>No V0 suggestion available</h2>
      )}

      <FlexStartRow>
        <div
          className={"Round-button"}
          style={{ marginTop: "2rem" }}
          onClick={() => {
            suggestV0Timings(
              observation.round.weather.speed_of_sound,
              observation.device.position,
              observation.target!.position,
              observation.device.position.master,
              observation.shot.ammunition,
              (gunshotSampleNumber - sonicBoomSampleNumber) / 48000,
              jwtToken
            ).then((v0) => {
              if (v0) {
                setSuggestedV0(Math.floor(v0));
                updateV0(Math.floor(v0));
              }
            });
          }}>
          Calculate V0
        </div>
        <div
          className={"Round-button"}
          style={{ marginTop: "2rem", opacity: suggestedV0 ? 1 : 0.5 }}
          onClick={() => {
            if (suggestedV0) {
              if (!observation.shot.ammunition?.id || !observation.shot.weapon?.id) {
                alert("No ammunition or weapon id available");
              } else {
                persistV0(suggestedV0, observation.shot.ammunition.id, observation.shot.weapon.id, jwtToken).then(
                  (v0) => {
                    if (v0) {
                      setSuggestedV0(Math.floor(v0));
                      updateV0(Math.floor(v0));
                    }
                  }
                );
              }
            }
          }}>
          Persist calculated V0
        </div>
      </FlexStartRow>
    </div>
  );
};

export default AmmoOverlay;
