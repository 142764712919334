import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { ApplicationState } from "../reducers";
import { CalculatedTiming } from "../model/timing";
import midpoint from "@turf/midpoint";
import { MapViewType } from "./MapViewType";
import { GunshotObservation } from "../model/rounds";

export interface MapboxProps {
  timing?: CalculatedTiming;
  round?: GunshotObservation;
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  mapType?: MapViewType;
}

export interface Props extends OwnProps, MapboxProps {}

function addTimingLayer(map: Map) {
  map.addLayer({
    id: "timings",
    interactive: false,
    layout: {
      "icon-allow-overlap": true,
      "text-field": ["get", "distance"],
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 12,
      "text-allow-overlap": true

    },
    paint: {
      "text-color": ["get", "color"],
      "text-halo-color": "#000",
      "text-halo-width": 1,
    },
    source: "timings",
    type: "symbol"
  });
}

function addTimingSource(map: Map, timing?: CalculatedTiming, round?: GunshotObservation) {
  map.addSource("timings", {
    data: timingLines(timing, round),
    type: "geojson"
  });
}

export const timingLines = (
  timing?: CalculatedTiming,
  round?: GunshotObservation
): GeoJSON.FeatureCollection => {
  return {
    features: createFeatures(timing, round),
    type: "FeatureCollection"
  };
};

const createFeatures = (timing?: CalculatedTiming, round?:GunshotObservation): any => {
  const features = [];
  if (timing && round) {

    const midG2O = midpoint(
      [round.device.position.master.lon, round.device.position.master.lat],
      [round.device.position.lon, round.device.position.lat],
    );
    
    // @ts-ignore
    features.push({
      geometry: midG2O.geometry,
      id: "g2o",
      properties: {
        distance: `
        Muzzle to device: ${(timing.maxSonicBoom.muzzleBlastTravelTime * 1000).toFixed(0)}ms`,
        id: `g2o`,
        color: `white`
      },
      type: "Feature"
    });

      [timing.minSonicBoom, timing.maxSonicBoom].forEach((sonicBoom, i) => {
        if (sonicBoom.shockWaveTrajectory) {
          const midS2O = midpoint(
            [sonicBoom.shockWaveTrajectory.origin.longitude, sonicBoom.shockWaveTrajectory.origin.latitude],
            [sonicBoom.shockWaveTrajectory.end.longitude, sonicBoom.shockWaveTrajectory.end.latitude]
          );
          // @ts-ignore
          features.push({
            geometry: midS2O.geometry,
            id: `S2o_${i}`,
            properties: {
              distance: `SonicBoom: ${(sonicBoom.totalShockWaveTravelTime * 1000).toFixed(0)}ms`,
              id: `S2o`,
              color: `white`
            },
            type: "Feature"
          });
        }
      })
      
  }
  return features;
};

function updateTimingSource(map: Map, timing?: CalculatedTiming, round?: GunshotObservation) {
  map
    .getSource("timings")
    // @ts-ignore
    .setData(timingLines(timing, round));
}

const TimingLayer = ({ map, loaded, timing, round }: Props) => {
  if (map && loaded) {
    if (!map.getSource("timings")) {
      addTimingSource(map, timing, round);
      addTimingLayer(map);
    } else {
      updateTimingSource(map, timing, round);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedRound = state.round.selectedRound;
  const observation =
    selectedRound &&
    selectedRound.observations.find(
      o => o.device.name === state.round.selectedDevice
    );

  return {
    timing: observation ? observation.calculatedTiming : undefined,
    round: observation
  };
};

// @ts-ignore
export default connect<MapboxProps, {}, {}>(mapStateToProps)(TimingLayer);
